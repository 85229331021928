import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";

const Faqrodocrm = ({ data }) => {
  return (
    <FunctionPageLayout title="FAQ RODO i CRM">
      <SEO
        title="RODO a CRM"
        description="Rodo w CRM ✅ Najczęściej zadawane pytania • Zapytaj o bezpłatne demo!"
        keywords={["rodo w crm"]}
      />
      <h3>
        <strong>Co to jest RODO?</strong>
      </h3>
      <p>
        {" "}
        RODO, czyli{" "}
        <strong>Ogólne Rozporządzenie o Ochronie Danych Osobowych</strong> (ang.
        General Data Protection Regulation, GDPR) to rozporządzenie Unii
        Europejskiej, zawierające przepisy, dotyczące{" "}
        <strong>ochrony osób fizycznych</strong> w związku z{" "}
        <strong>przetwarzaniem ich danych osobowych</strong>. Celem wprowadzenia
        rozporządzenia było{" "}
        <strong>ujednolicenie prawa ochrony danych osobowych</strong> na terenie
        całej Unii Europejskiej oraz zapewnienie swobodnego przepływu danych.
        Nowe przepisy muszą być stosowane od 25 maja 2018 r.
      </p>
      <br />
      <h3>
        <strong>Czy RODO mnie dotyczy?</strong>
      </h3>
      <p>
        <strong>
          Przepisy RODO dotyczą wszystkich podmiotów, które przetwarzają dane
          osobowe.
        </strong>{" "}
        Tym samym obowiązują każdą firmę, która w procesie obsługi klientów,
        działań marketingowych czy sprzedażowych, przetwarza dane osobowe.
      </p>
      <br />
      <h3>
        <strong>Czym są dane osobowe w świetle RODO?</strong>
      </h3>
      <p>
        {" "}
        Zgodnie z art. 4 ust. 1 RODO <strong>dane osobowe</strong> to informacje
        o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej;{" "}
      </p>
      <p>
        Możliwa do zidentyfikowania osoba fizyczna to osoba, którą można
        bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie
        identyfikatora takiego jak:
      </p>
      <p>
        <ul>
          <li>imię i nazwisko,</li>
          <li>numer identyfikacyjny,</li>
          <li>dane o lokalizacji,</li>
          <li>identyfikator internetowy,</li>
          <li>
            jeden bądź kilka szczególnych czynników określających: fizyczną,
            fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub
            społeczną tożsamość osoby fizycznej;{" "}
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong>Czym są "szczególne" kategorie danych osobowych?</strong>
      </h3>
      <p>
        Szczególne kategorie danych osobowych to pojęcie, które{" "}
        <strong>
          zastępuje dotychczas obowiązujące „wrażliwe” danych osobowych
        </strong>{" "}
        (pojęcie z polskiej Ustawy o Ochronie Danych Osobowych). Art. 9 ust. 1
        RODO wymienia dane, które zalicza się do kategorii szczególnych,
        jednocześnie zabraniając ich przetwarzania co do zasady. Są to dane,
        dotyczące:
      </p>
      <p>
        <ul>
          <li>Pochodzenia rasowego i etnicznego</li>
          <li>Poglądów politycznych</li>
          <li>Przekonań religijnych i światopoglądowych</li>
          <li>Przynależności do związków zawodowych</li>
          <li>Stanu zdrowia</li>
        </ul>
      </p>
      <br />
      <h3>
        <strong> Kto jest administratorem danych osobowych?</strong>
      </h3>
      <p>
        Zgodnie z unijnym rozporządzeniem, administratorem danych osobowych
        <strong>może być każdy podmiot</strong> – osoba fizyczna, osoba prawna,
        organ publiczny, jednostka organizacyjna lub jakikolwiek podmiot
        nieposiadający osobowości prawnej. Taki podmiot staje się
        administratorem, jeśli samodzielnie lub wspólnie z innymi podmiotami
        ustala cele i sposoby przetwarzania danych osobowych. Tym samym{" "}
        <strong>
          administratorem danych osobowych jest każda firma, w której ustala się
          cele i sposoby przetwarzania danych osobowych.
        </strong>
      </p>
      <br />
      <h3>
        <strong>Kim jest procesor danych osobowych?</strong>
      </h3>
      <p>
        {" "}
        Mianem procesora określa się{" "}
        <strong>podmiot przetwarzający dane osobowe</strong>. Nie decyduje on o
        celu i sposobie ich przetwarzania, a jedynie o{" "}
        <strong>narzędziach</strong> ku temu służących. Procesor to podmiot,
        który na zlecenie administratora przechowuje, utrwala, organizuje,
        modyfikuje, udostępnia, niszczy czy usuwa dane osobowe.
      </p>
      <br />
      <h3>
        <strong>Jak system CRM chroni dane osobowe?</strong>
      </h3>
      <p>
        Jako dostawcy oprogramowania dla firm bardzo wysoko cenimy sobie kwestie
        bezpieczeństwa danych naszych klientów. W ramach ochrony danych dbamy o:
      </p>
      <p>
        <ol>
          <li>
            <strong>Bezpieczeństwo systemów</strong> – nasze systemy
            wykorzystują zabezpieczenia protokołu SSL, które chronią przed
            nieautoryzowanym dostępem do zasobów systemu.
          </li>
          <li>
            <strong>Bezpieczeństwo logowania</strong> – systemy wymuszają
            utworzenie silnego i unikalnego hasła, blokują też dostęp po
            kilkukrotnym podaniu błędnego hasła.
          </li>
          <li>
            <strong>Uprawnienia pracowników</strong> – kontrola dostępu dzięki
            tworzeniu poziomów uprawnień dostępu.
          </li>
          <li>
            <strong>Monitorowanie przetwarzania danych</strong> – systemy
            zapisują datę i godzinę utworzenia kartoteki kontrahenta. Dostępna
            jest pełna historia każdej edycji danych wraz z osobą, która zmian
            dokonała.
          </li>
          <li>
            <strong>Możliwość usunięcia danych z systemu</strong> – zgodnie z
            RODO, każdy konsument ma prawo do tego, aby jego dane przestały być
            przetwarzane.
          </li>
        </ol>
      </p>
      <br />
      <h3>
        <strong>
          Co mogę zrobić, aby zapewnić ochronę danych moich klientów?
        </strong>
      </h3>
      <p>
        <ol>
          <li>
            Po pierwsze – dbaj o <strong>bezpieczeństwo systemu</strong>. Nie
            zapisuj haseł w przeglądarce, nie udostępniaj ich osobom postronnym
            i staraj się regularnie zmieniać hasła do systemu jak i do poczty
            e‐mail.
          </li>
          <li>
            drugie – zapewnij swoim pracownikom{" "}
            <strong>niezbędną wiedzę i przeszkolenie</strong> w zakresie prawa
            ochrony danych osobowych.
          </li>
          <li>
            Po trzecie – przechowuj{" "}
            <strong>dane w jednym, dobrze chronionym miejscu</strong>, jakim
            jest system CRM. Nie trzymaj delikatnych danych na{" "}
            <strong>dyskach twardych</strong> i niezabezpieczonych serwerach.
            <strong>
              Nie przechowuj danych osobowych w otwartych szufladach, szafach
              czy katalogach
            </strong>
            .
          </li>
          <li>
            Po czwarte – zbieraj tylko te dane, które są{" "}
            <strong>absolutnie niezbędne</strong>. Minimalizacja danych polega
            na gromadzeniu danych wyłącznie w sytuacji, kiedy są one
            niezaprzeczalnie potrzebne np. do procesu sprzedaży lub działań
            marketingowych.
          </li>
          <li>
            Po piąte – zapoznaj się z <strong>treścią RODO</strong>, a nie tylko
            z publikacjami internetowymi.
          </li>
          <li>
            Po szóste – wprowadź{" "}
            <strong>własną politykę ochrony danych osobowych</strong>. Zastanów
            się, jak możesz zmienić podejście do danych,{" "}
            <strong>zapewniając klientom pełną ochronę</strong> ich tożsamości i{" "}
            <strong>zachowując płynność procesów sprzedażowych.</strong>
            Zaprojektuj własny, wewnętrzny zbiór zasad, dotyczących
            przetwarzania danych osobowych, który będzie zgodny z{" "}
            <strong>przepisami prawa i wartościami</strong>
            przez Twoją firmę.
          </li>
        </ol>
      </p>
      <br />
      <h3>
        <strong> Czy mogę wysyłać mailing z systemu CRM?</strong>
      </h3>
      <p>
        Tak, ale musisz pamiętać o tym, że każda osoba, do której wysyłasz
        treści marketingowe <strong>musi wyrazić na to zgodę</strong>. Zadbaj o
        to, aby zbierane przez Ciebie zgody były jednoznaczne, proste i
        zrozumiałe.{" "}
        <strong>
          Dbaj o to, aby przesyłać tylko wartościowe treści i oferty
        </strong>
        . Nie przesyłaj SPAM‐u i daj możliwość wypisania się z listy
        mailingowej.
      </p>
      <br />
      <h3>
        <strong> Jakie kary grożą za nieprzestrzeganie RODO?</strong>
      </h3>
      <p> Środki administracyjne:</p>
      <p>
        <ol>
          <li>
            <strong>Ostrzeżenia</strong> dla administratora bądź podmiotu
            przetwarzającego
          </li>
          <li>
            <strong>Upomnienia</strong> dla administratora bądź podmiotu
            przetwarzającego
          </li>
          <li>
            <strong>Nakaz</strong> spełnienia żądania osoby, której dane
            dotyczą,{" "}
          </li>
          wynikającego z praw przysługujących jej na mocy rozporządzenia (np.
          prawo do bycia zapomnianym)
          <li>
            <strong>Zakaz</strong> lub ograniczenie przetwarzania danych
          </li>
          <li>
            <strong>Inne</strong> – m. in. cofnięcie certyfikacji lub nakaz
            zawiadomienia osoby, której dane naruszono.
          </li>
        </ol>
      </p>
      <p>Administracyjne kary pieniężne</p>
      <p>
        <ol>
          <li>
            <strong>Kary pieniężne</strong> w wysokości do 10 mln euro, lub 2 %
            całkowitego rocznego obrotu światowego przedsiębiorstwa z
            poprzedniego roku.
          </li>
          <li>
            Nieprzestrzeganie nakazu orzeczonego przez organ nadzorczy (art. 58.
            ust. 2.) podlega administracyjnej karze w wysokości do 20 mln euro,
            lub 4% całkowitego rocznego obrotu światowego przedsiębiorstwa z
            poprzedniego roku.
          </li>
        </ol>
      </p>
      <p>
        W rzeczywistości jednak <strong>kary będą ustalane wedle skali</strong>,
        charakteru i indywidualnej sytuacji każdego podmiotu.
        <strong>
          Nie należy się więc spodziewać wysokich kar pieniężnych w przypadku
          niewielkich uchybień.{" "}
        </strong>
        Nie mniej jednak zabezpieczenie danych osobowych powinno być
        priorytetem.
      </p>
      <br />
      <h3>
        <strong>
          Jak zarządzić uprawnieniami dostępu do danych osobowych?
        </strong>
      </h3>
      <p>
        {" "}
        Uprawnienia w systemie CRM pozwalają na zarządzanie informacjami, do
        których mają dostęp poszczególni pracownicy. System pozwala na
        utworzenie różnych profili np. profil „kierownik” lub „specjalista”.
        <strong>Do każdego profilu przydzielane są uprawnienia</strong>, a na
        ich podstawie użytkownik ma dostęp do wybranych lub wszystkich
        informacji w systemie. Dostęp do danych osobowych powinni mieć tylko
        uprawnieni pracownicy, których charakterystyka pracy wymusza korzystanie
        z nich.
      </p>
    </FunctionPageLayout>
  );
};

export default Faqrodocrm;
