import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Container from "./container";
import styles from "./functionPageLayout.module.css";
import Hero from "./HeroBanner";
import Layout from "./layout";

const FunctionPageLayout = ({ title, headline, noDemo, children }) => {
  const data = useStaticQuery(graphql`
    query {
      heroBg1: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroBg2: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Hero heroImage={data.heroBg1} justifyContent="center">
        <h1>{title}</h1>
        <br />
        <h2>{headline}</h2>
      </Hero>
      <Container>
        <div className={styles.contentWrapper}>{children}</div>
      </Container>
      {noDemo ? null : (
        <Hero
          heroImage={data.heroBg2}
          maxHeight="200px"
          justifyContent="center"
        >
          <p>Wypróbuj bezpłatnie system. 30 dni za darmo. Bez zobowiązań.</p>
          <div
            style={{
              textAlign: "center",
              margin: "20px",
            }}
          >
            <Link className={styles.startNowButton} to="/demo/">
              Wypróbuj teraz
            </Link>
          </div>
        </Hero>
      )}
    </Layout>
  );
};

export default FunctionPageLayout;
